import axios from "axios";
import Swal from "sweetalert2";
import i18n from "../config/i18n";
import {handleError} from "./other.service";

export function getStaff() {
  return axios.get(`/a/users?limit=500`);
}

export function addUser(user, callback) {
  axios.post(`/a/users`, user)
    .then(() => {
      Swal.fire('', i18n.t("alerts:userAdded"), 'success').then(() => callback)
    })
    .catch(err => handleError(err));
}

export function editUser(user, callback) {
  axios.put(`/a/users`, user)
    .then(() => {
      Swal.fire('', i18n.t("alerts:userEdited"), 'success').then(() => callback)
    })
    .catch(err => handleError(err));
}

export function toggleUser(id, type) {
  return axios.put(`/a/users/${id}/${type}`, {});
}
