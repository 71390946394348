import excell_icon from "../resources/img/icons/excell_icon.svg";
import thumb_up from "../resources/img/icons/thumb_up.svg";
import thumb_down from "../resources/img/icons/thumb_down.svg";
import av_timer from "../resources/img/icons/av_timer.svg";
import error_outline from "../resources/img/icons/error_outline.svg";
import boxes from "../resources/img/icons/boxes.svg";
import ship from "../resources/img/icons/shipping.svg";
export const xlsIcon = excell_icon;
export const thumbUp = thumb_up;
export const thumbDown = thumb_down;
export const timer = av_timer;
export const warning = error_outline;
export const totalBox = boxes;
export const shipping = ship;