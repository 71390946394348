export const cities = [
  {
    name: {
      ru: 'Астана',
      kk: 'Астана',
      en: 'Astana'
    },
    code: '01',
    location: {
      lat: 51.129922,
      lng: 71.438145
    }
  },
  {
    name: {
      ru: 'Алматы',
      kk: 'Алматы',
      en: 'Almaty'
    },
    code: '02',
    location: {
      lat: 43.238293,
      lng: 76.945465
    }
  },
  {
    name: {
      ru: 'Караганда',
      kk: 'Қарағанды',
      en: 'Qaragandy'
    },
    code: '09',
    location: {
      lat: 49.807754,
      lng: 73.088504
    }
  },
  {
    name: {
      ru: 'Темиртау',
      kk: 'Темиртау',
      en: 'Temirtau'
    },
    code: '091',
    location: {
      lat: 50.058469,
      lng: 72.975473
    }
  },
  {
    name: {
      ru: 'Павлодар',
      kk: 'Павлодар',
      en: 'Pavlodar'
    },
    code: '14',
    location: {
      lat: 52.285577,
      lng: 76.940947
    }
  },
  {
    name: {
      ru: 'Шымкент',
      kk: 'Шымкент',
      en: 'Shymkent'
    },
    code: '17',
    location: {
      lat: 42.315514,
      lng: 69.586907
    }
  },
  {
    name: {
      ru: 'Усть-Каменогорск',
      kk: 'Өскемен',
      en: 'Óskemen'
    },
    code: '16',
    location: {
      lat: 49.948759,
      lng: 82.628459
    }
  },
  {
    name: {
      ru: 'Актобе',
      kk: 'Ақтөбе',
      en: 'Aktobe'
    },
    code: '04',
    location: {
      lat: 50.300371,
      lng: 57.154555
    }
  },
  {
    name: {
      ru: 'Петропавловск',
      kk: 'Петропавл',
      en: 'Petropavlovsk'
    },
    code: '15',
    location: {
      lat: 54.867254,
      lng: 69.137319
    }
  },
  {
    name: {
      ru: 'Актау',
      kk: 'Ақтау',
      en: 'Aktau'
    },
    code: '12',
    location: {
      lat: 43.635601,
      lng: 51.168254
    }
  },
  {
    name: {
      ru: 'Кокшетау',
      kk: 'Көкшетау',
      en: 'Kokshetau'
    },
    code: '03',
    location: {
      lat: 53.285140,
      lng: 69.375509
    }
  },
  {
    name: {
      ru: 'Тараз',
      kk: 'Тараз',
      en: 'Taraz'
    },
    code: '08',
    location: {
      lat: 42.897794,
      lng: 71.401002
    }
  },
  {
    name: {
      ru: 'п.Отеген Батыра',
      kk: 'п.Отеген Батыра',
      en: 'п.Отеген Батыра'
    },
    code: '05',
    location: {
      lat: 43.425224,
      lng: 77.019312
    }
  },
  {
    name: {
      ru: 'Кызылорда',
      kk: 'Кызылорда',
      en: 'Кызылорда'
    },
    code: '11',
    location: {
      lat: 44.823759,
      lng: 65.547818
    }
  },
  {
    name: {
      ru: 'Туркестан',
      kk: 'Туркестан',
      en: 'Туркестан'
    },
    code: '13',
    location: {
      lat: 43.277982,
      lng: 68.295234
    }
  },
  {
    name: {
      ru: 'Талгар',
      kk: 'Талгар',
      en: 'Талгар'
    },
    code: '092',
    location: {
      lat: 43.304393,
      lng: 77.248106
    }
  },
  {
    name: {
      ru: 'Талдыкорган',
      kk: 'Талдыкорган',
      en: 'Талдыкорган'
    },
    code: '093',
    location: {
      lat: 45.018668,
      lng: 78.389811
    }
  },
  {
    name: {
      ru: 'Конаев',
      kk: 'Конаев',
      en: 'Конаев'
    },
    code: '094',
    location: {
      lat: 43.870256,
      lng: 77.082955
    }
  },
  {
    name: {
      ru: 'Жезказган',
      kk: 'Жезказган',
      en: 'Жезказган'
    },
    code: '095',
    location: {
      lat: 47.773717,
      lng: 67.716648
    }
  },
  {
    name: {
      ru: 'Экибастуз',
      kk: 'Экибастуз',
      en: 'Экибастуз'
    },
    code: '096',
    location: {
      lat: 51.723554,
      lng: 75.33258
    }
  },
  {
    name: {
      ru: 'Балхаш',
      kk: 'Балхаш',
      en: 'Балхаш'
    },
    code: '097',
    location: {
      lat: 46.829256,
      lng: 74.981538
    }
  },
  {
    name: {
      ru: 'Жанаозен',
      kk: 'Жанаозен',
      en: 'Жанаозен'
    },
    code: '098',
    location: {
      lat: 43.345014,
      lng: 52.875999
    }
  },
  {
    name: {
      ru: 'Костанай',
      kk: 'Костанай',
      en: 'Костанай'
    },
    code: '10',
    location: {
      lat: 53.211889,
      lng: 63.657884
    }
  },
  {
    name: {
      ru: 'Тобол',
      kk: 'Тобол',
      en: 'Тобол'
    },
    code: '099',
    location: {
      lat: 53.206383,
      lng: 63.721103
    }
  },
  {
    name: {
      ru: 'Атырау',
      kk: 'Атырау',
      en: 'Атырау'
    },
    code: '06',
    location: {
      lat: 47.104046,
      lng: 51.932182
    }
  },
  {
    name: {
      ru: 'Уральск',
      kk: 'Уральск',
      en: 'Уральск'
    },
    code: '07',
    location: {
      lat: 51.19551,
      lng: 51.433704
    }
  },
  {
    name: {
      ru: 'Семей',
      kk: 'Семей',
      en: 'Семей'
    },
    code: '080',
    location: {
      lat: 50.408125,
      lng: 80.270429
    }
  },
  {
    name: {
      ru: 'Каскелен',
      kk: 'Каскелен',
      en: 'Каскелен'
    },
    code: '021',
    location: {
      lat: 43.193794,
      lng: 76.627598
    }
  },
];
