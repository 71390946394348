import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { withNamespaces } from "react-i18next";
import { FlexWrap, FlexRow } from "./flexboxes";
import { DatePicker } from "./datepicker";

const Label = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 0.875rem;
  color: #969ec5;
`;

const RadioButton = styled(Label)`
  & label {
    border-bottom: 1px dashed #969ec5;
  }
  & input[type="radio"] {
    display: none;
    &:checked {
      & + label {
        font-weight: bold;
        border-bottom: 1px solid transparent;
      }
    }
  }
`;

const DateBlock = styled(FlexWrap)`
  align-items: center;
  &:first-of-type {
    margin-right: 0.5rem;
  }
  ${Label} {
    margin-right: 0.75rem;
    font-weight: ${props => (props.active ? 700 : 400)};
    border-bottom: 1px dashed
      ${props => (props.active ? "transparent" : "#969ec5")};
  }
  > input {
    height: 100%;
  }
`;

const Button = styled.button`
  background: transparent;
  border: 0;
  font-weight: normal;
  line-height: normal;
  font-size: 0.875rem;
  color: #969ec5;
  border-bottom: 1px solid #969ec5;
  cursor: pointer;
`;

class SortByPeriod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      periodFilter: "forToday",
      from: "",
      to: ""
    };
  }

  componentDidMount() {
    if (this.props.onlyPeriod) this.setState({ periodFilter: "forPeriod" });
  }

  statsFilter = (value, isPeriod) => {
    this.setState(
      {
        periodFilter: value
      },
      () => {
        const { periodFilter, from, to } = this.state;
        if (!isPeriod) {
          this.setState(
            {
              from:
                periodFilter === "forToday"
                  ? moment().format("YYYY-MM-DD")
                  : "",
              to:
                periodFilter === "forToday" ? moment().format("YYYY-MM-DD") : ""
            },
            () => this.props.parentCallback(this.state.from, this.state.to)
          );
        } else if (this.props.onlyDay) {
          this.props.parentCallback(from, from);
        } else if (this.props.onlyPeriod) {
          this.props.parentCallback(from, to);
        } else if (from && to) {
          this.props.parentCallback(from, to);
        }
      }
    );
  };

  changeDay = type => {
    const day =
      type === "+"
        ? moment(this.props.from).add(1, "days")
        : moment(this.props.from).subtract(1, "days");
    this.props.parentCallback(day, day);
  };

  render() {
    const { t, onlyDay, onlyPeriod, hideForAllTime } = this.props;
    const { periodFilter, from, to } = this.state;
    return (
      <React.Fragment>
        {onlyDay ? (
          <FlexWrap>
            <DateBlock active={periodFilter === "forPeriod"}>
              <Label>{t("chooseDay")}</Label>
              <Button onClick={() => this.changeDay("-")}>{`<`}</Button>
              <DatePicker
                value={
                  this.props.from
                    ? moment(this.props.from).format("YYYY-MM-DD")
                    : from
                }
                max={moment().format("YYYY-MM-DD")}
                onChange={e =>
                  this.setState({ from: e.target.value }, () =>
                    this.statsFilter("forPeriod", true)
                  )
                }
              />
              <Button onClick={() => this.changeDay("+")}>{`>`}</Button>
            </DateBlock>
          </FlexWrap>
        ) : onlyPeriod ? (
          <FlexWrap>
            <DateBlock active={periodFilter === "forPeriod"}>
              <Label>{t("from")}</Label>
              <DatePicker
                value={
                  this.props.from
                    ? moment(this.props.from).format("YYYY-MM-DD")
                    : from
                }
                max={to}
                onChange={e =>
                  this.setState({ from: e.target.value }, () =>
                    this.statsFilter("forPeriod", true)
                  )
                }
              />
            </DateBlock>
            <DateBlock active={periodFilter === "forPeriod"}>
              <Label>{t("to")}</Label>
              <DatePicker
                value={
                  this.props.to
                    ? moment(this.props.to).format("YYYY-MM-DD")
                    : to
                }
                min={from}
                onChange={e =>
                  this.setState({ to: e.target.value }, () =>
                    this.statsFilter("forPeriod", true)
                  )
                }
              />
            </DateBlock>
          </FlexWrap>
        ) : (
          <FlexRow innerSpace="1em" marginBottom="0.5em" wrapItems>
            <RadioButton>
              <input
                type="radio"
                name="periodFilter"
                id="forToday"
                checked={periodFilter === "forToday"}
                onChange={() => this.statsFilter("forToday")}
              />
              <label htmlFor="forToday">{t("forToday")}</label>
            </RadioButton>
            {!hideForAllTime ? (
              <RadioButton>
                <input
                  type="radio"
                  name="periodFilter"
                  id="forAllTime"
                  checked={periodFilter === "forAllTime"}
                  onChange={() => this.statsFilter("forAllTime")}
                />
                <label htmlFor="forAllTime">{t("forAllTime")}</label>
              </RadioButton>
            ) : null}
            <FlexWrap>
              <DateBlock active={periodFilter === "forPeriod"}>
                <Label>{t("from")}</Label>
                <DatePicker
                  value={from}
                  max={to}
                  onChange={e =>
                    this.setState({ from: e.target.value }, () =>
                      this.statsFilter("forPeriod", true)
                    )
                  }
                />
              </DateBlock>
              <DateBlock active={periodFilter === "forPeriod"}>
                <Label>{t("to")}</Label>
                <DatePicker
                  value={to}
                  min={from}
                  onChange={e =>
                    this.setState({ to: e.target.value }, () =>
                      this.statsFilter("forPeriod", true)
                    )
                  }
                />
              </DateBlock>
            </FlexWrap>
          </FlexRow>
        )}
      </React.Fragment>
    );
  }
}

export default withNamespaces("tracking")(SortByPeriod);
