import * as React from "react";
import { withNamespaces } from "react-i18next";
import MainLayout from "../layouts/main";
import { handleError } from "../services/other.service";
import {getOrderById, getStatistics} from "../services/tracking.service";
import { Title } from "../components/title.js";
import { CardRow, Card } from "../components/cards.js";
import {Flex, FlexRow, Row} from "../components/flexboxes.js";
import {totalBox, timer, shipping} from "../components/icons";
import SortByPeriod from "../components/sortByPeriod";
import {Refresh} from "../components/button";
import {Search} from "../components/searchInput";
import CustomSelect from "../components/customSelect";
import {orderStatuses} from "../resources/constants/statuses";
import Pagination from "../components/pagination";
import {getOrders} from "../services/intercity.service";
import {InformButton, Table, Td, Th, Tr} from "../components/flexibleTable";
import {Status} from "../components/status";
import moment from "moment/moment";
import OrderDetails from "./modals/orderDetails";

class InterCity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from: null,
            to: null,
            statistics: {
                picked: "",
                withdraw: "",
                dropAvg: "",
                pickAvg: ""
            },
            showModal: "",
            showInfo: false,
            lockersStats: [],
            lockersActionsStats: {},
            listByAction: [],
            currentAction: "",
            refreshing: false,
            searchKey: '',
            status: "ALL",
            page: 0,
            ordersCount: 0,
            perPage: props.cookies.get("tableRowsPerPage") || 10,
            sortBy: "",
            orders: [],
            order: ""
        };
    }

    componentDidMount() {
        this.loadStats();
        this.getOrders();
    }

    loadStats = (from, to) => {
        if (from && to)
            this.setState({
                from: from,
                to: to
            });
        getStatistics(new Date(from).getTime(), new Date(to).getTime())
            .then(res =>
                this.setState({ statistics: res.data, refreshingStats: false })
            )
            .catch(err => {
                handleError(err);
                this.setState({
                    statistics: {
                        picked: "-",
                        withdraw: "-",
                        dropAvg: "-",
                        pickAvg: "-"
                    }
                });
            });
        this.getOrders(new Date(from).getTime(), new Date(to).getTime());
    };


    refresh = () => {
        this.setState({ refreshingStats: true }, () =>
            this.loadStats(this.state.from, this.state.to)
        );
    };

    onPageChanged = data => {
        const { currentPage } = data;
        this.setState({ page: currentPage - 1 }, () => this.getOrders());
    };

    getOrders(from = null, to = null) {
        const { page, perPage, searchKey, status, sortBy, order} = this.state;
        getOrders(perPage, page, searchKey, status, sortBy, order, new Date(from).getTime(), new Date(to).getTime())
            .then(res =>
                this.setState({
                    orders: res.data.list,
                    ordersCount: res.data.count,
                    refreshing: false
                })
            )
            .catch(err => handleError(err));
    }

    loadOrderById = id => {
        getOrderById(id)
            .then(res =>
                this.setState({ chosenOrder: res.data, showModal: "orderModal" })
            )
            .catch(err => handleError(err));
    };

    closeModal = (refresh, reloadPage) => {
        this.setState(
            {
                page: reloadPage ? 0 : this.state.page,
                showModal: ""
            },
            () => {
                if (refresh) this.refresh();
            }
        );
    };
    changeStatus = status => {
        this.setState({ page: 0, status }, () =>
            this.getOrders()
        );
    };
    render() {
        const { t } = this.props;
        const {
            statistics,
            searchKey,
            sortBy,
            ordersCount,
            perPage,
            page,
            order,
        } = this.state;

        const tableHeaders = [
            { title: t("trackNumber") },
            { title: t("status") },
            { title: t("sender") },
            { title: t("cityDestination")},
            {
                title: t("cellBookingTime"),
                value: "bookDate",
                icon:
                    sortBy === "bookDate" && order === "asc" ? (
                        <i className="material-icons">arrow_drop_down</i>
                    ) : sortBy === "bookDate" && order === "desc" ? (
                        <i className="material-icons">arrow_drop_up</i>
                    ) : null
            },
            {
                title: t("lastStatusTime"),
                value: "modifyDate",
                icon:
                    sortBy === "modifyDate" && order === "asc" ? (
                        <i className="material-icons">arrow_drop_down</i>
                    ) : sortBy === "modifyDate" && order === "desc" ? (
                        <i className="material-icons">arrow_drop_up</i>
                    ) : null
            },
            { title: "" }
        ];

        return (
            <React.Fragment>
                <Row justifyContent="space-between" marginBottom="0.5rem" fullWidth>
                    <Row justifyContent="flex-start" alignItems="center" fullWidth>
                        <Title>{t("quickStats")}</Title>
                        <Refresh
                            click={this.refresh}
                            isLoading={this.state.refreshing}
                        />
                    </Row>
                    <SortByPeriod parentCallback={this.loadStats} />
                    <Row justifyContent="space-between" marginBottom="0.5em" fullWidth>
                        <Row justifyContent="flex-start" fullWidth>
                            <Search
                                value={searchKey}
                                placeholder={t('search')}
                                onChange={e => this.setState({ searchKey: e.target.value })}
                                submit={this.searchByKey}
                            />
                            <CustomSelect
                                value={t(`statuses:${this.state.status}`)}
                                options={orderStatuses}
                                click={this.changeStatus}
                            />
                        </Row>
                    </Row>
                </Row>

                <CardRow>
                    <Card
                        icon={totalBox}
                        digit={ordersCount}
                        label={t("totalParcels")}
                        info={t("parcelsPickedInfo")}
                    />
                    <Card
                        icon={shipping}
                        digit={"1"}
                        label={t("shipping")}
                        info={t("shippingInfo")}
                    />
                    <Card
                        icon={timer}
                        digit={statistics.dropAvg}
                        label={t("averageDropSpeed")}
                        info={t("parcelsPickedInfo")}
                    />
                    <Card
                        icon={timer}
                        digit={statistics.pickAvg}
                        label={t("averagePickSpeed")}
                        info={t("parcelsPickedInfo")}
                    />
                </CardRow>
                <Row
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="0.5rem"
                    fullWidth
                >
                    <Row justifyContent="flex-start" alignItems="center" fullWidth>
                        <Title>{t("tracking")}</Title>
                        <Refresh click={this.refresh} isLoading={this.state.refreshing} />
                    </Row>
                </Row>
                <Row justifyContent="flex-end" marginBottom="0.5em" fullWidth>
                    <Pagination
                        totalRecords={ordersCount}
                        currentPage={page + 1}
                        pageLimit={perPage}
                        pageNeighbours={1}
                        onPageChanged={this.onPageChanged}
                    />
                </Row>
                <Table
                    fullWidth
                    headers={tableHeaders.map((h, index) => (
                        <Th
                            key={index}
                            onClick={h.value ? () => this.sortBy(h.value) : null}
                            clickable={h.value}
                        >
                            <Flex justifyContent={"center"} alignItems={"center"}>
                                {h.title}
                                {h.icon}
                            </Flex>
                        </Th>
                    ))}
                >
                    {this.state.orders.length > 0 ? (
                        this.state.orders.map((o, index) => (
                            <Tr key={index}>
                                <Td>{o.senderOrderFullDto.identificator}</Td>
                                <Td>
                                    <Status
                                        type={
                                            o.senderOrderFullDto.onWithdraw && o.senderOrderFullDto.status === "SENT"
                                                ? "onWITHDRAWN"
                                                : o.senderOrderFullDto.status
                                        }
                                    >
                                        {t(
                                            `statuses:${
                                                o.senderOrderFullDto.onWithdraw && o.senderOrderFullDto.status === "SENT"
                                                    ? "onWITHDRAWN"
                                                    : o.senderOrderFullDto.status
                                            }`
                                        )}
                                    </Status>
                                </Td>
                                <Td>{o.senderOrderFullDto.client.fullname} - {o.senderOrderFullDto.client.phone}</Td>
                                <Td>{o.orderIntercityDto.recipientCity === 'Алматы'
                                    ? 'Астана → ' + o.orderIntercityDto.recipientCity
                                    : o.orderIntercityDto.recipientCity === 'Астана'
                                        ? 'Алматы → ' + o.orderIntercityDto.recipientCity : null}</Td>
                                <Td>{moment(o.senderOrderFullDto.bookDate).format("DD.MM.YY - HH:mm")}</Td>
                                <Td>
                                    {o.status === "RESERVED"
                                        ? moment(o.senderOrderFullDto.bookDate).format("DD.MM.YY - HH:mm")
                                        : o.status === "UNRESERVED"
                                            ? moment(o.senderOrderFullDto.unbookDate).format("DD.MM.YY - HH:mm")
                                            : o.status === "SENT"
                                                ? moment(o.senderOrderFullDto.dropDate).format("DD.MM.YY - HH:mm")
                                                : o.status === "END"
                                                    ? moment(o.senderOrderFullDto.pickDate).format("DD.MM.YY - HH:mm")
                                                    : o.status === "WITHDRAWN"
                                                        ? moment(o.senderOrderFullDto.withdrawDate).format("DD.MM.YY - HH:mm")
                                                        : moment(o.senderOrderFullDto.modifyDate).format("DD.MM.YY - HH:mm")}
                                </Td>
                                <Td>
                                    <FlexRow justifyContent="center" innerSpace="0.25rem">
                                        <InformButton
                                            title={t("actions:seeInfo")}
                                            onClick={() => this.loadOrderById(o.senderOrderFullDto.id)}
                                        >
                                            i
                                        </InformButton>
                                    </FlexRow>
                                </Td>
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={5}>{t("global:nothingFound")}</Td>
                        </Tr>
                    )}
                </Table>
                {this.state.showModal === "orderModal" ? (
                    <OrderDetails
                        order={this.state.chosenOrder}
                        changeForWithdrawal={this.changeForWithdrawal}
                        close={(refresh, reloadPage) =>
                            this.closeModal(refresh, reloadPage)
                        }
                        reload={() => this.loadOrderById(this.state.chosenOrder.id)}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default withNamespaces("intercity")(MainLayout(InterCity));