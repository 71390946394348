import * as React from "react";
import { withNamespaces } from "react-i18next";
import MainLayout from "../layouts/main";
import { handleError } from "../services/other.service";
import { getStatistics } from "../services/tracking.service";
import {
  getLockersStatistics,
  getLockerActionsStatistics,
  getListByAction
} from "../services/lockers.service";
import { Title } from "../components/title.js";
import { CardRow, Card } from "../components/cards.js";
import { Row } from "../components/flexboxes.js";
import { thumbUp, thumbDown, timer } from "../components/icons";
import SortByPeriod from "../components/sortByPeriod";
import { lockerStatusesImgs } from "../resources/constants/statuses";
import ListByLockerAction from "./modals/listByLockerAction";
import { Refresh } from "../components/button";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: null,
      to: null,
      statistics: {
        picked: "",
        withdraw: "",
        dropAvg: "",
        pickAvg: ""
      },
      showInfo: false,
      lockersStats: [],
      lockersActionsStats: {},
      listByAction: [],
      currentAction: "",
      refreshingStats: false,
      refreshingLockersStats: false
    };
  }

  componentDidMount() {
    this.loadStats(new Date().getTime(), new Date().getTime());
    this.loadLockersStats();
    this.getLockerActionsStatistics();
  }

  loadStats = (from, to) => {
    if (from && to)
      this.setState({
        from: from,
        to: to
      });
    getStatistics(new Date(from).getTime(), new Date(to).getTime())
      .then(res =>
        this.setState({ statistics: res.data, refreshingStats: false })
      )
      .catch(err => {
        handleError(err);
        this.setState({
          statistics: {
            picked: "-",
            withdraw: "-",
            dropAvg: "-",
            pickAvg: "-"
          }
        });
      });
  };

  loadLockersStats = () => {
    getLockersStatistics()
      .then(res =>
        this.setState({
          lockersStats: res.data.list
        })
      )
      .catch(err => {
        handleError(err);
        this.setState({
          lockersStats: []
        });
      });
  };

  getLockerActionsStatistics = () => {
    getLockerActionsStatistics()
      .then(res =>
        this.setState({
          lockersActionsStats: res.data,
          refreshingLockersStats: false
        })
      )
      .catch(err => handleError(err));
  };

  getListByAction = action => {
    getListByAction(action.toUpperCase())
      .then(res =>
        this.setState({
          currentAction: action,
          listByAction: res.data.list,
          showInfo: true
        })
      )
      .catch(err => handleError(err));
  };

  refreshStats = () => {
    this.setState({ refreshingStats: true }, () =>
      this.loadStats(this.state.from, this.state.to)
    );
  };

  refreshLockersStats = () => {
    this.setState({ refreshingLockersStats: true }, async () => {
      await this.loadLockersStats();
      this.getLockerActionsStatistics();
    });
  };

  render() {
    const { t } = this.props;
    const { statistics } = this.state;
    return (
      <React.Fragment>
        <Row justifyContent="space-between" marginBottom="0.5rem" fullWidth>
          <Row justifyContent="flex-start" alignItems="center" fullWidth>
            <Title>{t("quickStats")}</Title>
            <Refresh
              click={this.refreshStats}
              isLoading={this.state.refreshingStats}
            />
          </Row>
          <SortByPeriod parentCallback={this.loadStats} />
        </Row>

        <CardRow>
          <Card
            icon={thumbUp}
            digit={statistics.picked}
            label={t("parcelsPicked")}
            info={t("parcelsPickedInfo")}
          />
          <Card
            icon={thumbDown}
            digit={statistics.withdraw}
            label={t("parcelsWithdrawn")}
            info={t("parcelsPickedInfo")}
          />
          <Card
            icon={timer}
            digit={statistics.dropAvg}
            label={t("averageDropSpeed")}
            info={t("parcelsPickedInfo")}
          />
          <Card
            icon={timer}
            digit={statistics.pickAvg}
            label={t("averagePickSpeed")}
            info={t("parcelsPickedInfo")}
          />
        </CardRow>
        {this.state.lockersStats.length > 0 ? (
          <Row
            justifyContent="flex-start"
            alignItems="center"
            marginTop="1em" marginBottom="1em"
            fullWidth
          >
            <Title>{t("lockers")}</Title>
            <Refresh
              click={this.refreshLockersStats}
              isLoading={this.state.refreshingLockersStats}
            />
          </Row>
        ) : null}
        <CardRow>
          {this.state.lockersStats.map((l, i) => (
            <Card
              key={i}
              icon={lockerStatusesImgs[l.status]}
              digit={l.count}
              label={t(`statuses:${l.status}`)}
              info={t(`statusesInfo:${l.status}`)}
            />
          ))}
        </CardRow>
        <CardRow>
          {Object.entries(this.state.lockersActionsStats).map((l, i) => (
            <Card
              key={i}
              icon={lockerStatusesImgs[l[0]]}
              digit={l[1]}
              label={t(`statuses:off.${l[0]}`)}
              info={t(`statusesInfo:${l[0]}`)}
              click={l[1] > 0 ? () => this.getListByAction(l[0]) : null}
            />
          ))}
        </CardRow>
        {this.state.showInfo ? (
          <ListByLockerAction
            action={this.state.currentAction}
            lockers={this.state.listByAction}
            close={() => this.setState({ showInfo: false })}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withNamespaces("dashboard")(MainLayout(Dashboard));
