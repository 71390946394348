import * as React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import NotFound from "./404.js";
import Authorization from "./authorization.js";
import Tracking from "./tracking.js";
import Dashboard from "./dashboard.js";
import Lockers from "./lockers.js";
import Staff from "./staff.js";
import Settings from "./settings/index.js";
import FAQ from "./FAQ.js";
import Requests from "./requests.js";
import Dictionaries from "./dictionaries.js";
import Tariffs from "./tariffs";
import Billing from "./billing";
import Systems from "./systems";
import Intercity from "./intercity";

const Index = () => {
  return <div />;
};

class Pages extends React.Component {
  Tariffs;
  render() {
    return (
      <Switch>
        <Route path="/" component={Index} exact />
        <Route path="/authorization" component={Authorization} />
        <Route path="/tracking" component={Tracking} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/staff" component={Staff} />
        <Route path="/lockers" component={Lockers} />
        <Route path="/faq" component={FAQ} />
        <Route path="/settings/:step?" component={Settings} />
        <Route path="/requests" component={Requests} />
        <Route path="/dictionaries" component={Dictionaries} />
        <Route path="/tariffs" component={Tariffs} />
        <Route path="/billing" component={Billing} />
        <Route path="/systems" component={Systems} />
        <Route path="/intercity" component={Intercity} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default withRouter(Pages);
